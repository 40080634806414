import {
  maxDashboardWidth,
  maxHorizontalCardWidth,
  maxVerticalCardWidth
} from 'const/styles'
import styled, { css } from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { spacing } from 'syf-component-library/defaultTheme'
import { Inset } from 'syf-component-library/ui/spacing'
import { Body } from 'syf-component-library/ui/typography'
import theme from 'theme'

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const DashboardContainer = styled(Inset)`
  width: 100%;

  @media ${query.greaterThanLarge} {
    max-width: ${maxDashboardWidth}px;
  }
`

export const DashboardAccountGrid = styled.div`
  display: flex;
  margin: 0 auto;
  padding-bottom: ${spacing.large}px;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: ${spacing.small}px;

  @media ${query.greaterThanSmall} {
    gap: ${spacing.base}px;
    justify-content: center;
  }

  @media ${query.greaterThanMedium} {
    gap: ${spacing.xlarge}px;
  }
  * {
    box-sizing: border-box;
  }
`
export const AccountManagerContainer = styled.div`
  max-width: ${maxHorizontalCardWidth}px;
  @media ${query.betweenSmallMedium} {
    max-width: ${maxVerticalCardWidth}px;
  }
`

const AccountCardMobileView = css`
  justify-items: left;
  text-align: center;
`

export const AccountManagerGrid = styled(Inset)`
  display: grid;
  grid-template-columns: auto auto;
  gap: ${spacing.base}px;
  justify-content: left;
  align-items: center;
  border-radius: 5px;
  padding: 0px;
  @media ${query.lessThanMedium} {
    ${AccountCardMobileView}
    grid-template-columns: 100%;
    margin: 0 ${spacing.small}px;
    padding: 12px;
  }
`

export const AccountManagerText = styled(Body)`
  color: ${theme.white};
`

import { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import updateGivenName from 'redux/givenName/actions'

import decodeJWTToken from 'authorization-module/helpers/decodeJWTToken'
import AuthCallback, {
  AuthResponseProps
} from 'authorization-module/ui/atoms/Callback'
import config from 'const/config'
import routes from 'const/routes'
import authenticationRedirect from 'helpers/authenticationRedirect'
import getApigeeClientId from 'helpers/getApigeeClientId'
import getQueryParam from 'syf-js-utilities/formats/getQueryParam'
import { analyticsConfig } from 'syf-js-utilities/helpers/triggerAnalyticsEvent'

const Callback = (): ReactElement => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const errorParam = getQueryParam('error')
  const stateParam = getQueryParam('state')
  const previousRoute = sessionStorage.getItem(stateParam)

  const authorizedCallback = () => {
    /*
     * OTP Error
     * http://localhost:3000/callback?error=user_declined&state=guaHUPaI&eror_description=user_something
     */
    if (previousRoute && errorParam) {
      sessionStorage.removeItem(stateParam)
      sessionStorage.setItem('otpErrorCallbackParams', window.location.search)

      // Generating a new token
      authenticationRedirect({
        navigateTo: previousRoute,
        scope: 'read write'
      })

      return
    }

    /*
     * Errors on callback URL
     * http://localhost:3000/callback?error=server_error&error_description=unexpected_error_occurred
     */
    if (errorParam) {
      authenticationRedirect()
      return
    }

    navigate(routes.HOME, { replace: true })
  }

  const successCallback = (authResponse: AuthResponseProps) => {
    const { id_token: idToken } = authResponse
    const { givenName } = decodeJWTToken(idToken)
    sessionStorage.setItem('idToken', idToken)
    sessionStorage.setItem('givenName', givenName)
    const correlationId = sessionStorage.getItem('correlationId')
    analyticsConfig.defaults.Token = correlationId
    window?.newrelic?.addToTrace({ correlationId })
    window?.newrelic?.setUserId(correlationId)

    dispatch(updateGivenName(givenName))

    // OTP Success or OTP new token generator after an error
    if (stateParam && previousRoute) {
      sessionStorage.removeItem(stateParam)

      navigate(previousRoute, { replace: true })
      return
    }

    navigate(routes.HOME, { replace: true })
  }

  const errorCallback = () => {
    navigate(routes.ERROR, { replace: true })
  }

  const redirectUri = `${window.location.origin}${config.BASENAME}${routes.CALLBACK}`
  const client = localStorage.getItem('client') || getQueryParam('client')
  if (client) localStorage.setItem('client', client)
  const apigeeClientId = getApigeeClientId(client)

  return (
    <AuthCallback
      authorizedCallback={authorizedCallback}
      successCallback={successCallback}
      reAttemptAuthorization={() => navigate(routes.HOME)}
      apigeeClientId={apigeeClientId}
      redirectUri={redirectUri}
      apiHost={config.API_HOST}
      errorCallback={errorCallback}
    />
  )
}

export default Callback

import { ReactElement, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router'
import { updateModalStatus, updateSelectedAccount } from 'redux/modals/actions'
import { RootState } from 'redux/store'

import {
  ACCOUNT_CREDIT_LINE_INCREASE_ELIGIBLE,
  ACCOUNT_DELINQUENT
} from 'const/account/conditions'
import { PSCC, RC } from 'const/account/products'
import featureFlags from 'const/featureFlags'
import { FAR_MESSAGE_PEN } from 'const/icons'
import routes from 'const/routes'
import { useAccountFeatures, useAccountSummary } from 'const/syfApiQueries'
import useNavigateOnRedirect from 'hooks/useNavigateOnRedirect'
import { useIVASetChatStatus } from 'iva-react-web-ui'
import i18n from 'strings/i18n'
import { VerticalCardLoader } from 'syf-component-library/ui/atoms'
import triggerAnalyticsEvent, {
  analyticsConfig
} from 'syf-js-utilities/helpers/triggerAnalyticsEvent'
import { IVALaunchPointName } from 'typings/custom'

import { creditLimitIncreaseContainerId } from 'const/modalContainerId'
import {
  AccountServiceContainer,
  AccountServiceWrapper,
  Header,
  Icon,
  LinkButton,
  LinkContainer,
  LinkHeader,
  LinkItem,
  LinkList,
  LoaderContainer
} from './subcomponents'

const accountServiceAnalyticText = 'account services'

const AccountServices = (): ReactElement => {
  const dispatch = useDispatch()
  const { pointOfNeedChat } = useIVASetChatStatus()

  const { selectedAccount } = useSelector(
    (state: RootState) => ({
      selectedAccount:
        state.modals.selectedAccount ||
        sessionStorage.getItem('selectedAccountId')
    }),
    shallowEqual
  )

  const {
    data: { conditions = [], product } = {},
    isLoading: isAccountSummaryLoading,
    isError: isAccountSummaryError
  } = useAccountSummary(selectedAccount)

  const { data: accountFeatures } = useAccountFeatures(selectedAccount)
  const accessToManageAuthUser = accountFeatures?.accessToManageAuthUser

  useNavigateOnRedirect()

  useEffect(() => {
    analyticsConfig.defaults.Product = product?.type
    analyticsConfig.defaults.ClientName = product?.clientId

    triggerAnalyticsEvent({
      PageFunction: accountServiceAnalyticText,
      PageKind: accountServiceAnalyticText,
      pageName: 'view'
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isAccountSummaryError) {
    return <Navigate to={routes.ERROR} />
  }

  const isAccountCreditLimitIncreaseEnabled =
    conditions?.includes(ACCOUNT_CREDIT_LINE_INCREASE_ELIGIBLE) &&
    accountFeatures?.accessToCreditLimitIncrease

  const isDelinquent = conditions?.includes(ACCOUNT_DELINQUENT)

  const isRevolving = [RC, PSCC].includes(product?.lineOfBusiness)

  const handleIVALaunch = (launchPON: IVALaunchPointName) => {
    pointOfNeedChat(launchPON, selectedAccount)
  }

  const handleCreditLimitIncreaseClick = () => {
    const creditLimitIncreaseButtonId = `credit-limit-increase-${selectedAccount}`

    if (featureFlags.isCreditLimitIncreaseEnabled) {
      dispatch(updateSelectedAccount(selectedAccount))
      dispatch(
        updateModalStatus('CreditLimitIncrease', creditLimitIncreaseButtonId)
      )
    } else {
      handleIVALaunch('CLI')
    }
  }

  return (
    <AccountServiceWrapper>
      {isAccountSummaryLoading ? (
        <LoaderContainer>
          <VerticalCardLoader />
        </LoaderContainer>
      ) : (
        <>
          <AccountServiceContainer>
            <Header weight="bold" data-test="account-services">
              {i18n({
                labels: 'accountsServices'
              })}
            </Header>
          </AccountServiceContainer>
          <LinkContainer>
            <LinkItem>
              <LinkHeader weight="bold">
                {i18n({
                  labels: 'accountActions'
                })}
              </LinkHeader>
              <LinkList>
                {isAccountCreditLimitIncreaseEnabled &&
                  isRevolving &&
                  !isDelinquent && (
                    <div id={creditLimitIncreaseContainerId(selectedAccount)}>
                      <LinkButton
                        buttonType="text"
                        data-type={accountServiceAnalyticText}
                        data-reason="credit limit increase"
                        data-object="button"
                        data-analytics="clicktrackingevent"
                        data-test="credit-limit-increase"
                        onClick={handleCreditLimitIncreaseClick}
                      >
                        {!featureFlags.isCreditLimitIncreaseEnabled && (
                          <Icon icon={FAR_MESSAGE_PEN} />
                        )}
                        {i18n({
                          button: 'creditLimitIncrease'
                        })}
                      </LinkButton>
                    </div>
                  )}
                {featureFlags.isManageCardHolderEnabled &&
                  accessToManageAuthUser && (
                    <LinkButton
                      buttonType="text"
                      data-type={accountServiceAnalyticText}
                      data-reason="manage cardholders"
                      data-object="link"
                      data-analytics="clicktrackingevent"
                      data-test="manage-cardholders"
                    >
                      {i18n({
                        button: 'manageCardholders'
                      })}
                    </LinkButton>
                  )}
                {(isDelinquent || isRevolving) && (
                  <LinkButton
                    buttonType="text"
                    data-type={accountServiceAnalyticText}
                    data-reason="change payment due date"
                    data-object="link"
                    data-analytics="clicktrackingevent"
                    data-test="change-payment-due-date"
                    onClick={() => handleIVALaunch('CPDD')}
                  >
                    <Icon icon={FAR_MESSAGE_PEN} />
                    {i18n({
                      button: 'changePaymentDueDate'
                    })}
                  </LinkButton>
                )}
              </LinkList>
            </LinkItem>
            <LinkItem>
              <LinkHeader weight="bold">
                {i18n({
                  labels: 'cardActions'
                })}
              </LinkHeader>

              <LinkList>
                {isRevolving && !isDelinquent && (
                  <>
                    <LinkButton
                      buttonType="text"
                      data-type={accountServiceAnalyticText}
                      data-reason="activate a card"
                      data-object="link"
                      data-analytics="clicktrackingevent"
                      data-test="activate-card"
                      onClick={() => handleIVALaunch('AC')}
                    >
                      <Icon icon={FAR_MESSAGE_PEN} />
                      {i18n({
                        button: 'activateACard'
                      })}
                    </LinkButton>
                    <LinkButton
                      buttonType="text"
                      data-type={accountServiceAnalyticText}
                      data-reason="freeze card"
                      data-object="link"
                      data-analytics="clicktrackingevent"
                      data-test="freeze-card"
                      onClick={() => handleIVALaunch('FC')}
                    >
                      <Icon icon={FAR_MESSAGE_PEN} />
                      {i18n({
                        button: 'freezeCard'
                      })}
                    </LinkButton>
                  </>
                )}

                {(isDelinquent || isRevolving) && (
                  <LinkButton
                    buttonType="text"
                    data-type={accountServiceAnalyticText}
                    data-reason="replace your card"
                    data-object="link"
                    data-analytics="clicktrackingevent"
                    data-test="replace-your-card"
                    onClick={() => handleIVALaunch('RC')}
                  >
                    <Icon icon={FAR_MESSAGE_PEN} />
                    {i18n({
                      button: 'replaceYourCard'
                    })}
                  </LinkButton>
                )}
              </LinkList>
            </LinkItem>
            <LinkItem>
              <LinkHeader weight="bold">
                {i18n({
                  labels: 'security'
                })}
              </LinkHeader>
              <LinkList>
                {(isDelinquent || isRevolving) && (
                  <LinkButton
                    buttonType="text"
                    data-type={accountServiceAnalyticText}
                    data-reason="report lost or stolen card"
                    data-object="link"
                    data-analytics="clicktrackingevent"
                    data-test="report-lost-stolen-card"
                    onClick={() => handleIVALaunch('RLS')}
                  >
                    <Icon icon={FAR_MESSAGE_PEN} />
                    {i18n({
                      button: 'reportLostStolenCard'
                    })}
                  </LinkButton>
                )}

                {isRevolving && !isDelinquent && (
                  <>
                    <LinkButton
                      buttonType="text"
                      data-type={accountServiceAnalyticText}
                      data-reason="manage travel notifications"
                      data-object="link"
                      data-analytics="clicktrackingevent"
                      data-test="manage-travel-notifications"
                      onClick={() =>
                        !featureFlags.isTravelNotificationsMfeEnabled &&
                        handleIVALaunch('MTN')
                      }
                    >
                      {!featureFlags.isTravelNotificationsMfeEnabled && (
                        <Icon icon={FAR_MESSAGE_PEN} />
                      )}
                      {i18n({
                        button: 'manageTravelNotifications'
                      })}
                    </LinkButton>
                    <LinkButton
                      buttonType="text"
                      data-type={accountServiceAnalyticText}
                      data-reason="create/change PIN"
                      data-object="link"
                      data-analytics="clicktrackingevent"
                      data-test="create-change-PIN"
                      onClick={() => handleIVALaunch('CCP')}
                    >
                      <Icon icon={FAR_MESSAGE_PEN} />
                      {i18n({
                        button: 'createChangePIN'
                      })}
                    </LinkButton>
                  </>
                )}
              </LinkList>
            </LinkItem>
          </LinkContainer>
        </>
      )}
    </AccountServiceWrapper>
  )
}

export default AccountServices

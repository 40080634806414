import { ReactElement, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { RootState } from 'redux/store'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import config from 'const/config'
import featureFlags from 'const/featureFlags'
import {
  FAL_ANGLE_LEFT,
  FAL_BELL,
  FAL_GLOBE,
  FAR_ANGLE_DOWN,
  FAR_CHART_LINE,
  FAR_SIGN_OUT_ALT,
  FAR_SQUARE_SLIDERS,
  FAR_TIMES,
  FAR_USER,
  FAS_USER_CIRCLE
} from 'const/icons'
import routes, { Routes } from 'const/routes'
import handleLogout from 'helpers/handleLogout'
import i18n from 'strings/i18n'
import useWindowWidth from 'syf-component-library/ui/customHooks/useWindowWidth'
import Navigation from 'syf-component-library/ui/patterns/SystemNavigation'

import {
  DropdownRow,
  IconWrapper,
  MenuItemText,
  NavigationContainer
} from './subcomponents'

export interface HeaderProps {
  handleLanguageChange: () => void
}

const backButtonPaths: Routes[] = [
  routes.ACCOUNT_SERVICES,
  routes.ACTIVITY,
  routes.ALERTS,
  routes.CREDIT_BALANCE_REFUND,
  routes.DISPUTES,
  routes.MARKETING_PRIVACY,
  routes.PROFILE,
  routes.REWARDS,
  routes.CREDIT_SCORE
]

const Header = ({ handleLanguageChange }: HeaderProps): ReactElement => {
  const { ASSETS_HOST } = config
  const [isLogoutDisabled, setIsLogoutDisabled] = useState(false)
  const location = useLocation()
  const { givenName } = useSelector((state: RootState) => state, shallowEqual)
  const shouldDisplayAlerts = featureFlags.isAlertsEnabled
  const shouldDisplayProfile = featureFlags.isProfileManagementEnabled
  const shouldDisplayMarketing = featureFlags.isMarketingPrivacySettingEnabled
  const shouldDisplayCreditScore = featureFlags.isVantageScoreMfeEnabled
  const windowWidth = useWindowWidth()
  const logoSrc =
    shouldDisplayAlerts && windowWidth.isLessThanTablet
      ? `${ASSETS_HOST}/assets/synchrony/syf_icon.svg`
      : `${ASSETS_HOST}/assets/synchrony/logo.svg`

  const navigate = useNavigate()

  const handleOnClick = () => {
    setIsLogoutDisabled(true)
    handleLogout()
  }
  const handleOnProfileClick = () => {
    navigate(routes.PROFILE)
  }

  const handleOnMarketingSettingClick = () => {
    navigate(routes.MARKETING_PRIVACY)
  }

  const handleAlertsClick = () => {
    navigate(routes.ALERTS)
  }

  const handleCreditScoreClick = () => {
    navigate(routes.CREDIT_SCORE)
  }

  const handleBackButtonClick = () => {
    navigate(routes.HOME)
  }

  const showBackAndAlerts =
    backButtonPaths.includes(location.pathname as Routes) && shouldDisplayAlerts

  const showAlerts = location.pathname === routes.HOME && shouldDisplayAlerts

  const dropDownList = [
    <DropdownRow
      onClick={handleOnClick}
      buttonType="text"
      key="sign-out"
      data-type="customer menu"
      data-reason="sign out"
      data-object="button"
      data-analytics="clicktrackingevent"
      data-test="sign-out-button"
      disabled={isLogoutDisabled}
    >
      <IconWrapper>
        <FontAwesomeIcon icon={FAR_SIGN_OUT_ALT} />
      </IconWrapper>
      <MenuItemText>{i18n({ button: 'signOut' })} </MenuItemText>
    </DropdownRow>
  ]

  if (shouldDisplayCreditScore) {
    dropDownList.unshift(
      <DropdownRow
        buttonType="text"
        onClick={handleCreditScoreClick}
        key="credit-score"
        data-type="customer menu"
        data-reason="credit score"
        data-object="button"
        data-analytics="clicktrackingevent"
        data-test="your-credit-score-button"
      >
        <IconWrapper>
          <FontAwesomeIcon icon={FAR_CHART_LINE} />
        </IconWrapper>
        <MenuItemText>{i18n({ button: 'yourCreditScore' })} </MenuItemText>
      </DropdownRow>
    )
  }

  if (shouldDisplayMarketing) {
    dropDownList.unshift(
      <DropdownRow
        onClick={handleOnMarketingSettingClick}
        buttonType="text"
        key="marketing-privacy-setting"
        data-type="customer menu"
        data-reason="marketing & privacy settings"
        data-object="button"
        data-analytics="clicktrackingevent"
        data-test="marketing-privacy-setting-button"
      >
        <IconWrapper>
          <FontAwesomeIcon icon={FAR_SQUARE_SLIDERS} />
        </IconWrapper>
        <MenuItemText>{i18n({ button: 'marketingSetting' })} </MenuItemText>
      </DropdownRow>
    )
  }
  if (shouldDisplayProfile) {
    dropDownList.unshift(
      <DropdownRow
        onClick={handleOnProfileClick}
        buttonType="text"
        key="profile-management"
        data-type="account"
        data-reason="Profile management"
        data-object="button"
        data-analytics="clicktrackingevent"
        data-test="profile-management-button"
      >
        <IconWrapper>
          <FontAwesomeIcon icon={FAR_USER} />
        </IconWrapper>
        <MenuItemText>{i18n({ button: 'profileManagement' })} </MenuItemText>
      </DropdownRow>
    )
  }

  return (
    <NavigationContainer data-test="vista-header">
      {location.pathname === routes.LOGOUT ? (
        <Navigation logo={logoSrc} altText="Synchrony" />
      ) : (
        <Navigation
          logo={logoSrc}
          logoRouteLink={routes.HOME}
          altText="Synchrony"
          dropDownList={dropDownList}
          profileName={givenName}
          profileIcon={<FontAwesomeIcon icon={FAS_USER_CIRCLE} />}
          dropDownIcon={<FontAwesomeIcon icon={FAR_ANGLE_DOWN} />}
          language={i18n({ button: 'languageText' }) as string}
          handleLanguageClick={handleLanguageChange}
          languageIcon={<FontAwesomeIcon icon={FAL_GLOBE} />}
          closeIcon={<FontAwesomeIcon icon={FAR_TIMES} size="lg" />}
          {...(showAlerts && {
            alertsTooltip: i18n({ button: 'alertsTooltip' }) as string,
            alertsIcon: <FontAwesomeIcon icon={FAL_BELL} />,
            handleAlertsClick
          })}
          {...(showBackAndAlerts && {
            alertsTooltip: i18n({ button: 'alertsTooltip' }) as string,
            alertsIcon: <FontAwesomeIcon icon={FAL_BELL} />,
            handleAlertsClick,
            backButtonIcon: <FontAwesomeIcon icon={FAL_ANGLE_LEFT} />,
            backButtonLabel: i18n({ button: 'accountManager' }) as string,
            handleBackButtonClick
          })}
        />
      )}
    </NavigationContainer>
  )
}

export default Header

import { ReactElement, useEffect, useRef } from 'react'

import AddAccountButton from 'App/AddAccountModal/AddAccountButton'
import Loader from 'authorization-module/ui/atoms/Loader'
import featureFlags from 'const/featureFlags'
import queryClient from 'const/queryClient'
import {
  useAllAccountSummary,
  useAutoRegisterUpdate,
  useCreditAccounts
} from 'const/syfApiQueries'
import getAutoRegisterPayload from 'helpers/getAutoRegisterPayload'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import WelcomeInterstitial from 'pages/AccountsDashboard/WelcomeInterstitial'
import i18n from 'strings/i18n'
import { creditAccountsCacheKey } from 'syf-api'
import Stack from 'syf-component-library/ui/spacing/Stack'
import PersonalGreeting from 'ui/patterns/PersonalGreeting'

import Card from './Card'
import DynamicYieldGrid from './DynamicYieldGrid'
import {
  AccountManagerContainer,
  AccountManagerGrid,
  AccountManagerText,
  DashboardAccountGrid,
  DashboardContainer,
  PageWrapper
} from './subcomponents'
import TechnicalError from './TechnicalError'

const AccountsDashboard = (): ReactElement => {
  // To restrict from running in loop wrt creditaccount in case of any API issues
  const autoregisterCounter = useRef(0)
  const { isLoading: isAutoRegisterLoading, mutate: mutateAutoRegister } =
    useAutoRegisterUpdate()

  const {
    data: { accounts = [], electronicConsent } = {},
    isLoading: isAccountsLoading,
    error: accountError,
    isError: isCreditAccountsAPIError
  } = useCreditAccounts({
    onSuccess: (data) => {
      const accountsLength = data?.accounts?.length
      if (accountsLength) {
        triggerAnalyticsEvent({
          numberOfAccounts: `${accountsLength}`
        })
      } else {
        triggerAnalyticsEvent({
          pageName: 'error',
          MessageType: 'technical error',
          ErrorMessage: `${i18n({ errors: 'unableToFindAccount' })}`
        })
      }
      if (data.electronicConsent) {
        const accountsToAutoRegister = getAutoRegisterPayload(data.accounts)

        if (accountsToAutoRegister.length && autoregisterCounter.current < 1) {
          mutateAutoRegister(accountsToAutoRegister, {
            onSuccess: () => {
              queryClient.invalidateQueries(creditAccountsCacheKey())
              autoregisterCounter.current += 1
            }
          })
        }
      }
    }
  })

  const numberOfAccounts = accounts?.length

  useEffect(() => {
    if (isCreditAccountsAPIError) {
      triggerAnalyticsEvent({
        pageName: 'error',
        MessageType: 'technical error',
        ErrorMessage: `${i18n({ errors: 'technicalDifficulties' })}`
      })
    }
  }, [isCreditAccountsAPIError])

  const isAllAccountSummaryLoading = useAllAccountSummary(accounts).some(
    (queryData) => queryData.isLoading
  )

  const isAllAccountSummaryError = useAllAccountSummary(accounts).some(
    (queryData) => queryData.isError
  )

  const { isDYEnabled } = featureFlags
  const isAccountEmpty = !numberOfAccounts
  const previouslyVisited = localStorage.getItem('previouslyVisited')
  const isAccountsOrAutoRegisterLoading =
    isAccountsLoading || isAutoRegisterLoading
  const showDYCampaigns = !(
    isAllAccountSummaryLoading || isAccountsOrAutoRegisterLoading
  )
  const isCreditOrSummaryError =
    isCreditAccountsAPIError || isAllAccountSummaryError
  const renderWelcomeInterstitial =
    !isAccountsLoading &&
    (!previouslyVisited || !electronicConsent) &&
    !isAutoRegisterLoading &&
    !accountError &&
    !isAccountEmpty

  const showAddAccount =
    !isCreditAccountsAPIError &&
    !isAccountsLoading &&
    featureFlags.isAddAccountEnabled

  useEffect(() => {
    triggerAnalyticsEvent({
      PageFunction: 'dashboard',
      PageKind: 'multiaccount',
      previouslyVisited: `${Boolean(previouslyVisited)}`
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageWrapper data-test="account-dashboard">
      {renderWelcomeInterstitial && <WelcomeInterstitial />}
      <DashboardContainer vertical="base" hideOnBreakpointDown="small">
        <PersonalGreeting />
        {isAccountsOrAutoRegisterLoading ? (
          <Loader />
        ) : (
          <>
            {showAddAccount && (
              <AccountManagerContainer>
                <AccountManagerGrid id="add-account-container-id">
                  <AccountManagerText weight="bold">
                    {i18n({ labels: 'yourAccounts' })}
                  </AccountManagerText>
                  <AddAccountButton />
                </AccountManagerGrid>
              </AccountManagerContainer>
            )}
            <Stack hideOnBreakpointDown="small" />
            <DashboardAccountGrid>
              {accountError || isAccountEmpty ? (
                <TechnicalError
                  errorType={
                    accountError ? 'technicalError' : 'noAccountsFound'
                  }
                />
              ) : (
                <>
                  {accounts.map((account, index) => {
                    const { info } = account
                    const accountId = info['cipher.accountId']
                    return (
                      <Card
                        key={accountId}
                        accountId={accountId}
                        cardIndex={index}
                        data-test="account-card"
                      />
                    )
                  })}
                </>
              )}
            </DashboardAccountGrid>
          </>
        )}
      </DashboardContainer>
      {showDYCampaigns &&
        isDYEnabled &&
        !isCreditOrSummaryError &&
        !isAccountEmpty && <DynamicYieldGrid />}
    </PageWrapper>
  )
}

export default AccountsDashboard

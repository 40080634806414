import { ReactElement } from 'react'

import { LanguageObject } from './typings'

const strings: LanguageObject = {
  button: {
    accountManager: 'ADMINISTRADOR DE CUENTA',
    addCard: 'AGREGAR TARJETA',
    alertsTooltip: 'Administrar inscripciones de Alertas',
    clickHere: 'Hacer clic aquí',
    makeAPayment: 'Realizar un pago',
    paymentOptions: 'Ver Opciones de Pago',
    paymentPlan: 'Ver el Plan de Pagos ',
    reload: 'Refrescar',
    signOut: 'Cerrar sesión',
    activity: 'Actividad',
    back: 'ATRÁS',
    goBackHome: 'Regresar a la Página Principal',
    languageText: 'English',
    close: 'cerrar',
    agreeAndContinue: 'ACEPTAR Y CONTINUAR',
    welcomeConfirmation: '¡ENTENDIDO!',
    registerAccount: 'Registre la cuenta',
    viewMyAccount: 'Ver Mi Cuenta',
    statements: 'Estados De Cuenta',
    rewards: 'Recompensas',
    accountDetails: 'Detalles',
    viewAllActivity: 'Ver Toda la Actividad',
    activateCard: 'Activar Tarjeta',
    profileManagement: 'Administrar Perfil',
    marketingSetting:
      'Configuración de Comunicaciones Comerciales y Privacidad',
    more: 'Más',
    accountManagerReturn: 'REGRESAR AL ADMINISTRADOR DE CUENTA',
    creditBalanceRefund: 'Administre el Saldo a Su Favor',
    crossBorderOpenTab: 'ABRIR PESTAÑA',
    cancel: 'CANCELAR',
    creditLimitIncrease: 'Aumento del límite de crédito',
    changePaymentDueDate: 'Cambiar fecha de vencimiento de pago',
    activateACard: 'Activar una tarjeta',
    freezeCard: 'Congelar tarjeta',
    replaceYourCard: 'Reemplazar su tarjeta',
    reportLostStolenCard: 'Reportar tarjeta perdida o robada',
    manageTravelNotifications: 'Gestionar notificaciones de viaje',
    createChangePIN: 'Crear/cambiar PIN',
    addAccount: 'Agregar Cuenta',
    yourCreditScore: 'Su Calificación de Crédito',
    manageCardholders: 'Administrar titulares de tarjetas'
  },
  labels: {
    accountActions: 'Acciones de Cuenta',
    accountEndingIn: 'Cuenta que termina en',
    accountsServices: 'Servicios de Cuentas',
    cardActions: 'Acciones de Tarjeta',
    security: 'Seguridad',
    activityMenu: 'Actividad',
    loanEndingIn: 'Préstamo que termina en',
    dueDate: 'Fecha de pago',
    monthlyPaymentAmount: 'Monto del pago mensual',
    totalMinimumPayment: 'Total del Pago mínimo',
    noPaymentDue: 'No Hay un Pago a Vencer',
    forHelp: 'para obtener ayuda.',
    creditLimit: 'Límite de Crédito',
    currentBalance: 'Saldo Actual',
    totalPaid: 'Total Pagado',
    availableToSpend: 'Disponible para gastar',
    accountSnapshot: 'RESUMEN DE LA CUENTA',
    totalRemaining: 'Saldo Restante',
    monthlyPayment: ' Pago Mensual',
    currentBalanceTooltip: 'Podría no reflejar transacciones recientes.',
    unavailable: 'No disponible',
    recentActivity: 'Actividad Reciente',
    currentBalanceAlt: 'Información sobre Saldo Actual',
    loanAmount: 'Monto del préstamo',
    totalLoanBalance: '	Saldo Total\ndel Préstamo',
    nextAmountDue: 'Próximo \nMonto Adeudado',
    nextAmountDueTooltip: 'Incluye toda tarifa y todo monto vencido anterior.',
    totalLoanBalanceTooltip:
      'El Saldo Total del Préstamo puede cambiar por varias razones (por ej. pagos y devoluciones). Si corresponde, el Saldo Total del Préstamo puede aumentar a medida que se cumplen los pedidos.',
    crossBorderText:
      'La información compartida en este enlace no forma parte de Synchrony Bank. Tenga en cuenta que, como el sitio al que se dirige no está controlado por Synchrony Bank, usted estará sujeto a las condiciones de uso y la política de privacidad del sitio web que visite.',
    yourAccounts: 'Sus Cuentas',
    addAccount: 'Agregar Cuenta',
    addAccountContent:
      'Para agregar una nueva cuenta en la cual usted es el dueño principal, ingrese la información a continuación.',
    accountNumberPlaceholder: 'Número de Cuenta',
    ssnPlaceholder: 'Últimos 4 del número de seguro social',
    dobPlaceholder: 'Fecha de Nacimiento  (mm/dd/aaaa)',
    addAccountHelpText: 'Ingrese el número de cuenta',
    ssnHelpText: 'Ingrese los últimos 4 dígitos de su número de seguro social ',
    dobHelpText: 'Debe agregar su fecha de nacimiento'
  },
  rewardsModal: {
    modalTitle: 'Descripción de las Recompensas'
  },
  autopay: {
    text: 'Pago automático',
    on: 'ACTIVADO',
    off: 'DESACTIVADO'
  },
  overlayMessages: {
    accountUpgrading: '¡Su cuenta está siendo ascendida de categoría!',
    checkBackForDetails:
      'Regrese más tarde para ver los detalles de su cuenta.',
    attentionRequired: 'Se Requiere su Atención',
    checkBackLater: 'Por favor regrese más tarde',
    careCreditUnavailable:
      'Las recompensas Carecredit Rewards no están disponibles',
    contactInfo: 'Llame al servicio de atención al cliente al 1-800-542-5593.',
    detailsUnavailable: 'Detalles de la Cuenta no Disponibles',
    issueWithAccount: 'Hay un problema con su cuenta.',
    noInformation:
      'Estamos teniendo dificultades para obtener la información de esta cuenta.',
    setpayLoanProcessing:
      'Todavía estamos procesando los detalles de su préstamo de SetPay.',
    tryLater: 'Por favor intente más tarde.',
    activationReady: ({
      displayName
    }: {
      displayName: ReactElement
    }): ReactElement => <>TODO {displayName}.</>,
    clickActivateButton: 'TODO'
  },
  errors: {
    littleTrouble:
      'Lo sentimos, estamos teniendo un poco de dificultades para obtener esta información.',
    pleaseReload:
      'Por favor, refresque la página o intente de nuevo más tarde. Si el problema continúa,',
    pageNotFound:
      'La página que está buscando o no existe o no pudo ser encontrada.',
    sorryPage: 'Lo sentimos, no pudimos encontrar esta página.',
    weApologize: 'Nos disculpamos',
    technicalDifficulties:
      'Estamos experimentando dificultades técnicas en este momento y no podemos accesar a su cuenta.',
    unableToFindAccount:
      'Aunque su acceso fue exitoso, no podemos encontrar la cuenta asociada con sus credenciales.',
    tryAgain: 'Vuelve a intentar',
    tryAgainLater:
      'Por favor, intente más tarde, o contacte a servicio al cliente para asistencia.',
    accountClosedOrBlocked: 'Esta cuenta puede haber sido cerrada o bloqueada.',
    contactCustomerService:
      'Por favor, comuníquese con servicio al cliente al número que está al reverso de su tarjeta.',
    emptyAccountNumber: 'Ingrese el número de cuenta',
    emptySSN: 'Ingrese los últimos 4 dígitos de su número de seguro social ',
    emptyDOB: 'Ingrese su fecha de nacimiento (mm/dd/aaaa)',
    wrongAccountNumber: 'Verifique el número de cuenta',
    wrongSSN:
      'Son obligatorios los últimos 4 dígitos de su número de seguro social ',
    wrongDOB: 'Debe seguir el formato MM/DD/YYYY',
    pastDOB: 'El año de nacimiento está muy atrás en el pasado',
    futureDOB: 'Esta fecha está en el futuro',
    incorrectInfoDescripton:
      'Estamos teniendo problemas para encontrar esa cuenta.  Por favor, verifique e ingrese su información nuevamente.  Si cree que se trata de un error, llame al número de servicio al cliente que figura en el reverso de su tarjeta.'
  },
  welcomeInterstitial: {
    welcomeTitle: '	Bienvenido a su administrador de cuenta',
    manageAccountSubTitle:
      'Una forma más sencilla, rápida y cómoda de administrar sus cuentas de Synchrony.',
    legalStatement: 'acuerdo de comunicaciones',
    note: 'Importante:',
    welcomeNote:
      'Su acuerdo no cambia la configuración actual de envío de estado de cuenta. Su configuración de envío de estado de cuenta no cambiará a menos que se lo notifiquemos en el futuro o que usted haga los cambios luego de iniciar sesión en su cuenta.',
    eConsentStatement: ({
      legalStatementLink
    }: {
      legalStatementLink: ReactElement
    }): ReactElement => (
      <>
        Al usar el administrador de cuenta, usted acepta nuestro{' '}
        {legalStatementLink}.
      </>
    )
  },
  personalGreeting: {
    morning: 'Buenos Días',
    afternoon: 'Buenas Tardes',
    evening: 'Buenas Noches'
  },
  footer: {
    body: 'Sus cuentas de crédito son emitidas por Synchrony Bank. La Política de Privacidad de Synchrony Bank rige el uso sus cuentas de crédito. El uso de este sitio se rige por el uso de la Política de Privacidad de Internet de Synchrony Bank. Es posible que el contenido y los documentos que se muestran pueden no se proporcionen en español.',
    privacyPolicy: 'Política de Privacidad en Línea',
    privacyPolicyLink: 'privacy-policy-sp.html',
    usageAgreement: 'Acuerdo de uso en Línea',
    usageAgreementLink: 'terms-of-use-sp.html',
    fraudProtection: 'Protección contra Fraude',
    fraudProtectionlink: 'fraud-protection-sp.html',
    accessibility: 'Accesibilidad',
    accessibilityLink: 'web-accessibility-statement-sp',
    cookieSettings: 'Configuración de Cookies'
  },
  inactivityModal: {
    modalTitle: 'La sesión ha expirado',
    promptTitle: '¿Sigue allí?',
    countdownWarning: 'Su sesión en línea expirará en',
    continueButton: 'SIGO AQUÍ',
    logOffButton: 'CERRAR SESIÓN',
    seconds: 'segundos.'
  },
  logout: {
    pageTitle: 'Se ha cerrado la sesión',
    cardTitle: 'Gracias por administrar sus cuentas de Synchrony.',
    cardDescription: 'Se ha cerrado la sesión de forma segura.',
    button: 'INICIE SESIÓN DE NUEVO'
  },
  addAccountModal: {
    maxAttemptsTitle: 'Alcanzó el Máximo de Intentos',
    maxAttemptsDescription:
      'Por favor, vuelva a intentarlo más tarde o, si cree que se trata de un error, llame al número de atención al cliente que figura en el reverso de su tarjeta.',
    successTitle: 'Éxito',
    successDescription: '¡Hemos agregado su cuenta!',
    alreadyAddedTitle: 'Esta cuenta ya está agregada',
    alreadyAddedDescription:
      'Puede ver esta cuenta en su administrador de cuentas.',
    unableToAddTitle: 'La cuenta no puede ser agregada en este momento',
    syfIDErrorTitle: 'Algo salió mal al agregar esta cuenta',
    callCustomerService:
      'Por favor, llame al número de servicio al cliente que figura en el reverso de su tarjeta para obtener más información.',
    generalErrorTitle: 'Algo salió mal ',
    generalErrorDescription: 'Por favor, vuelva a intentarlo más tarde.',
    notSupportedTitle: 'Esta cuenta no es compatible con esta plataforma',
    notSupportedDescription:
      'Lo sentimos, esta cuenta no se puede utilizar en este sitio web.  Por favor utilice la manera existente de inicio de sesión para acceder a esta cuenta.'
  }
}

export default strings
